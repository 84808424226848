import React, { useContext, useState, useEffect ,} from "react";
// import Swiper from "swiper";
// import "swiper/swiper.min.css";
import "./App.css";
import AppContainer from "./AppContainer";
import Reinvest from "./Dashboard/Reinvest";
// import Register from "./components/Register";
// import Pricing from "./components/Pricing";
// import Faq from "./components/Faq";
// import Aboutus from "./components/Aboutus";
// import Markets from "./components/Markets";
// import Terms from "./components/Terms";
// import Privacy from "./components/Privacy";
// import Contact from "./components/Contact";
import { BrowserRouter, Link, Route, Switch} from "react-router-dom";
import { GlobalState } from "./Globalstate";
// import Forgotpass from "./components/Forgotpass";
// import Separatereg from "./components/Separatereg"
import Dashboard from "./Dashboard/Dashboard";
import Deposit from "./Dashboard/Deposit";
import Depositlist from "./Dashboard/Depositlist";
import Refferals from "./Dashboard/Refferals";
import Settings from "./Dashboard/Settings";
import Withdrawal from "./Dashboard/Withdrawal";
import ConfirmDepositInvest from "./Dashboard/ConfirmDepositInvest";
function App() {
  return (
    <>
      <GlobalState>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={AppContainer} />
            {/* <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/contact" component={Contact} />
            <Route path="/forgotpass" component={Forgotpass} />
            <Route path="/markets" component={Markets} />
            <Route path="/aboutus" component={Aboutus} />
            <Route path="/faq" component={Faq} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/register" component={Register} />
            <Route  path="/Separatereg" component={Separatereg} /> */}

            <Route  path="/homedash" component={Dashboard} />
            <Route  path="/deposit" component={Deposit} />
            <Route  path="/reinvest" component={Reinvest} />
            <Route  path="/depositlist" component={Depositlist} />
            <Route  path="/refferals" component={Refferals} />
            <Route  path="/settings" component={Settings} />
            <Route  path="/withdrawal" component={Withdrawal} />
            <Route  path="/confirmDepositInvest" component={ConfirmDepositInvest} />
            
          </Switch>
        </BrowserRouter>
      </GlobalState>
    </>
  );
}

export default App;
