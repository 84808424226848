import React from 'react';
import { useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  return (
    <div className="profile-layout__sidebar">
      <div className="js-sticky-sidebar">
        <div className="inner-wrapper-sticky">
          <a className="profile-layout__logo" href="/">
            <img src="/dashboard/assets/images/logo/logo.png" alt="" />
          </a>
          <ul className="profile-navigation">
            <li className={location.pathname === '/homedash' ? 'is-active' : ''}>
              <a href="/homedash">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-001-user-profile" />
                  </svg>
                </span>
                <span>Account</span>
              </a>
            </li>
            <li className={location.pathname === '/deposit' ? 'is-active' : ''}>
              <a href="/deposit">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-002-deposit" />
                  </svg>
                </span>
                <span>Deposit</span>
              </a>
            </li>
            <li className={location.pathname === '/reinvest' ? 'is-active' : ''}>
              <a href="/reinvest">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-002-deposit" />
                  </svg>
                </span>
                <span>Reinvest</span>
              </a>
            </li>
            <li className={location.pathname === '/withdrawal' ? 'is-active' : ''}>
              <a href="/withdrawal">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-002-wallet" />
                  </svg>
                </span>
                <span>Withdraw funds</span>
              </a>
            </li>
            <li className={location.pathname === '/depositlist' ? 'is-active' : ''}>
              <a href="/depositlist">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-double" />
                  </svg>
                </span>
                <span>My Deposits</span>
              </a>
            </li>
            <li className={location.pathname === '/refferals' ? 'is-active' : ''}>
              <a href="/refferals">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-scheme" />
                  </svg>
                </span>
                <span>Referrals</span>
              </a>
            </li>
            <li className={location.pathname === '/settings' ? 'is-active' : ''}>
              <a href="/settings">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-001-settings" />
                  </svg>
                </span>
                <span>Settings</span>
              </a>
            </li>
            <li className={location.pathname === '/' ? 'is-active' : ''}>
              <a href="/">
                <span className="profile-navigation__icon">
                  <svg className="svg-icon">
                    <use href="/dashboard/assets/icons/sprite.svg#icon-logout" />
                  </svg>
                </span>
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
