import firebase from 'firebase';
var firebaseConfig = {
  apiKey: "AIzaSyAdnPLHMOcE8bfPEDC2PXnkbmdFtA--FW4",
  authDomain: "streamline-globes.firebaseapp.com",
  databaseURL: "https://streamline-globes-default-rtdb.firebaseio.com",
  projectId: "streamline-globes",
  storageBucket: "streamline-globes.appspot.com",
  messagingSenderId: "394431967879",
  appId: "1:394431967879:web:987e41777600920400be22"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.firestore().settings({timestampsInSnapshots:true})

  export const f=firebase;
  export const database=firebase.database();
  export const storage=firebase.storage();
  export const auth=firebase.auth();
  export default firebase.firestore()